import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import { API_POPULATE_ALL, API_WATER_SYSTEMS } from "../../config/api";
import {
  ADD_WATER_SYSTEM,
  DELETE_WATER_SYSTEM,
  FETCH_WATER_SYSTEMS_REQUEST,
  UPDATE_WATER_SYSTEM,
  fetchWaterSystemsFailure,
  fetchWaterSystemsRequest,
  fetchWaterSystemsSuccess,
} from "../../store/actions/waterSystems.actions";
import { setWaterSystem } from "../../store/actions/waterSystem.actions";

const getWaterSystems = async (token, waterauthorityId) => {
  const url = `${API_WATER_SYSTEMS}${API_POPULATE_ALL}&pagination[limit]=-1`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleWaterSystems(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const waterauthorityId = action?.payload;
    yield put(showProgress());
    const response = yield call(getWaterSystems, token, waterauthorityId);
    const authSystems = getWaterSystemWithAuthority(
      response?.data,
      waterauthorityId
    );
    yield put(fetchWaterSystemsSuccess(authSystems));
    const firstSystem = authSystems?.[0]?.id;
    yield put(setWaterSystem(firstSystem));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWaterSystemsFailure(error.message));
  }
}

function getWaterSystemWithAuthority(systems, waterauthorityId) {
  const result = systems.filter(
    (sys) => sys?.attributes?.water_authority?.data?.id === waterauthorityId
  );
  return result;
}

export function* watchGetWaterSystems() {
  yield takeLatest(FETCH_WATER_SYSTEMS_REQUEST, handleWaterSystems);
}

const addWaterSystem = async (token, waterSystem) => {
  const url = `${API_WATER_SYSTEMS}`;
  const data = waterSystem;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, { data }, { headers });
  console.log(response.data);
  return response.data;
};

function* handleAddWaterSystem(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const waterauthorityId = yield select(
      (state) => state.waterAuthority.data.id
    );
    let waterSystem = action?.payload;
    waterSystem = { ...waterSystem, water_authority: waterauthorityId };
    yield put(showProgress());
    yield call(addWaterSystem, token, waterSystem);
    yield put(fetchWaterSystemsRequest(waterauthorityId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWaterSystemsFailure(error.message));
  }
}

export function* watchAddWaterSystem() {
  yield takeLatest(ADD_WATER_SYSTEM, handleAddWaterSystem);
}

const updateWaterSystem = async (token, waterSystem) => {
  const url = `${API_WATER_SYSTEMS}/${waterSystem.id}`;
  const data = waterSystem;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.put(url, data, { headers });
  return response.data;
};

function* handleUpdateWaterSystem(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const waterauthorityId = yield select(
      (state) => state.waterAuthority.data.id
    );
    let waterSystem = action?.payload;
    yield put(showProgress());
    yield call(updateWaterSystem, token, waterSystem);
    const waterSystems = yield call(getWaterSystems, token, waterauthorityId);
    yield put(fetchWaterSystemsRequest(waterauthorityId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWaterSystemsFailure(error.message));
  }
}

export function* watchUpdateWaterSystem() {
  yield takeLatest(UPDATE_WATER_SYSTEM, handleUpdateWaterSystem);
}

const deleteWaterSystem = async (token, waterSystem) => {
  const url = `${API_WATER_SYSTEMS}/${waterSystem.id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.delete(url, { headers });
  return response.data;
};

function* handleDeleteWaterSystem(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const waterauthorityId = yield select(
      (state) => state.waterAuthority.data.id
    );
    const waterSystem = action?.payload;
    yield call(deleteWaterSystem, token, waterSystem);
    const waterSystems = yield call(getWaterSystems, token, waterauthorityId);
    yield put(fetchWaterSystemsRequest(waterauthorityId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(fetchWaterSystemsFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchDeleteWaterSystem() {
  yield takeLatest(DELETE_WATER_SYSTEM, handleDeleteWaterSystem);
}
